import { useQuery } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import { getCurrentValues } from '../../utils/programsUtils'
import { getProgramList } from '../../api/program'
import { QUERY_KEYS } from '../../constants/queryKeys'

function useGetProgramFilter() {
   const location = useLocation()
   const params = getCurrentValues(location.search)
   const { loyaltyPlanIds, query, ...rest } = params

   return useQuery({
      queryKey: [QUERY_KEYS.GET_PROGRAM_FILTER, rest],
      queryFn: () => getProgramList(rest),
   })
}

export { useGetProgramFilter }
export default useGetProgramFilter
