import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

import useGetPartner from '../../../../../hooks/useGetPartner'
import useGetPrograms from '../../../../../hooks/useGetPrograms'
import ErrorPage from '../../../../common/ErrorPage'
import useGetChannelList from '../../../../../hooks/useGetChannelList'
import Form, { type OperationForm} from '../../common/Form'
import useCreateOperation from '../../../../../hooks/useCreateOperation'

export const CreatePartnetOperation = () => {
   const intl = useIntl()
   const { id, accountCode } = useParams()
   const navigate = useNavigate()
   const { data: partner, error } = useGetPartner(id)
   const { data: plans, error: planError } = useGetPrograms()
   const { data: channels, error: channelsError } = useGetChannelList()
   const { mutate, isPending } = useCreateOperation()

   if (error || planError || channelsError) {
      return <ErrorPage />
   }

   if (!partner || !plans?.items || !channels?.items) {
      return (
         <div className="absolute inset-0 bg-neutral-100 opacity-25 flex items-center justify-center">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
         </div>
      )
   }

   const goToOperation = () => navigate(`/${accountCode}/partners/${partner.id}/operations`)

   const breadcrumb = [
      {
         url: `/${accountCode}/partners/`,
         label: intl.formatMessage({ id: 'partner.operation.members' }),
      },
      {
         url: `/${accountCode}/partners/${partner.id}`,
         label: partner.externalId.toString(),
      },
      {
         url: `/${accountCode}/partners/${partner.id}/operations`,
         label: intl.formatMessage({ id: 'partner.operation.operations' }),
      },
      {
         label: intl.formatMessage({ id: 'partner.operation.create' }),
      },
   ]

   const onSubmit = (data: OperationForm) => {
      const { operation, partner } = data
      const {id,  ...request } = operation

      mutate(
         {
            ...request,
            memberId: partner.id.toString(),
            currency: 'MXN',
            account: accountCode!,
            amount: Number(request.amount),
            roomsPerNight: request.roomsPerNight ? Number(request.roomsPerNight) : undefined,
            numberOfNights:  request.numberOfNights ? Number(request.numberOfNights) : undefined,
            registationChannel: (request.registationChannel ?? '').toString(),
            serviceType: Number(request.serviceType),
         },
         {
            onSuccess: (response) => {
               navigate(`/${accountCode}/partners/${partner.id}/operations`)
               toast.success(intl.formatMessage({ id: 'operations.create.success' }))
            },
            onError: (error: any) => {
               toast.error(
                  error?.response?.data?.error
                     ? error.response.data.error
                     : intl.formatMessage({ id: 'operations.create.error' }),
               )
            },
         },
      )
   }

   return (
      <Form
         partner={partner}
         plans={plans.items}
         channels={channels.items}
         isPending={isPending}
         onSubmit={onSubmit}
         onCancel={goToOperation}
         breadcrumb={breadcrumb}
      />
   )
}

export default CreatePartnetOperation
