import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'

import { useGetOperation } from '../../../../../hooks/useGetOperation'
import useGetPartner from '../../../../../hooks/useGetPartner'
import useGetPrograms from '../../../../../hooks/useGetPrograms'
import ErrorPage from '../../../../common/ErrorPage'
import useGetChannelList from '../../../../../hooks/useGetChannelList'
import useUpdateOperation from '../../../../../hooks/useUpdateOperation'
import Form, { type OperationForm} from '../../common/Form'

export const EditOperation = () => {
   const intl = useIntl()
   const { id, operationId, accountCode } = useParams()
   const navigate = useNavigate()
   const { data: operation, error } = useGetOperation(operationId)
   const { data: partner } = useGetPartner(id)
   const { data: plans } = useGetPrograms()
   const { data: channels } = useGetChannelList()
   const { mutate, isPending } = useUpdateOperation()

   if (error) {
      return <ErrorPage />
   }

   if (!operation || !partner || !plans?.items || !channels?.items) {
      return (
         <div className="absolute inset-0 bg-neutral-100 opacity-25 flex items-center justify-center">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
         </div>
      )
   }

   const goToOperation = () => navigate(`/${accountCode}/partners/${partner.id}/operation/${operation.id}`)

   const breadcrumb = [
      {
         url: `/${accountCode}/partners/`,
         label: intl.formatMessage({ id: 'partner.operation.members' }),
      },
      {
         url: `/${accountCode}/partners/${operation.member.id}`,
         label: partner.externalId.toString(),
      },
      {
         url: `/${accountCode}/partners/${operation.member.id}/operations`,
         label: intl.formatMessage({ id: 'partner.operation.operations' }),
      },
      {
         label: intl.formatMessage({ id: 'partner.operation.operation' }, { operation: operation?.importId }),
      },
   ]

   const onSubmit = (data: OperationForm) => {
      const { operation } = data
      mutate(
         {
            invoiceNumber: operation.invoiceNumber,
            reference: operation.reference,
            id: operation.id.toString(),
         },
         {
            onSuccess: () => {
               toast.success(intl.formatMessage({ id: 'operations.update.success' }))
               goToOperation()
            },
            onError: (error: any) => {
               toast.error(
                  error?.response?.data?.error
                     ? error.response.data.error
                     : intl.formatMessage({ id: 'operations.update.error' }),
               )
            },
         },
      )
   }

   return (
      <Form
         isEdit
         operation={operation}
         partner={partner}
         plans={plans.items}
         channels={channels.items}
         isPending={isPending}
         onSubmit={onSubmit}
         onCancel={goToOperation}
         breadcrumb={breadcrumb}
      />
   )
}

export default EditOperation
