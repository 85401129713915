import { useIntl } from 'react-intl'
import { useForm, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import { Input, Select } from 'ui/fields'
import { Button } from 'ui'
import cx from 'classnames'

import Card from '../../../common/Card'
import CardItem from './CardItem'
import { Operation } from '../../../operations'
import { Channel, Partner } from '../..'
import { plans } from '../../../../api/program'
import Breadcrumb from '../../../common/Breadcrumb'
import useGetServiceTypes from '../../../../hooks/useGetServiceTypeList/useGetServiceTypeList'

type FormProps = {
   isEdit?: boolean
   operation?: Operation
   partner: Partner
   plans: Array<plans>
   channels: Array<Channel>
   breadcrumb: Array<{ url?: string; label: string }>
   onCancel: () => void
   isPending: boolean
   onSubmit: (data: OperationForm) => void
}

export type OperationForm = { operation: Operation; partner: Partner }

const Form = ({
   isEdit = false,
   operation,
   partner,
   plans,
   channels,
   breadcrumb,
   onCancel,
   isPending,
   onSubmit,
}: FormProps) => {
   const intl = useIntl()
   const {
      watch,
      register,
      handleSubmit,
      control,
      formState: { errors, dirtyFields },
      setValue,
   } = useForm<OperationForm>({
      mode: 'onChange',
      defaultValues: {
         operation: operation ?? ({} as Operation),
         partner,
      },
   })

   const loyaltyPlanId = watch('operation.loyaltyPlanId')
   const operationType = watch('operation.operationType')

   const { data: serviceTypes, isLoading: loadingServiceTypes } = useGetServiceTypes({
      ...(loyaltyPlanId && { loyaltyPlanId: loyaltyPlanId.toString() }),
      ...(operationType && { operationType: operationType.toString() }),
   })

   return (
      <>
         <Card>
            <Card.Body>
               <Breadcrumb items={breadcrumb} />
               <h2 className="text-3xl m-4 text-gray-500">
                  {intl.formatMessage({ id: `partner.operation.${isEdit ? 'edit' : 'create'}` })}
               </h2>
            </Card.Body>
         </Card>
         <Card className="m-4">
            <form onSubmit={handleSubmit(onSubmit)}>
               <Card.Body className="w-9/12 grid grid-cols-2 gap-4">
                  <CardItem
                     value={
                        <Input
                           label={intl.formatMessage({ id: 'partner.operation.amount' })}
                           type="number"
                           {...register('operation.amount', {
                              required: isEdit ? undefined : intl.formatMessage({ id: 'operation.errors.amount' }),
                           })}
                           disabled={isEdit}
                           error={errors?.operation?.amount?.message}
                           isTouched={dirtyFields?.operation?.amount}
                           step="0.01"
                           min={0}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Input
                           label={intl.formatMessage({ id: 'partner.operation.partner' })}
                           type="text"
                           disabled
                           value={`${partner.firstName} ${partner.lastName}`}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Controller
                           name="operation.loyaltyPlanId"
                           control={control}
                           rules={
                              isEdit
                                 ? undefined
                                 : { required: intl.formatMessage({ id: 'operation.errors.loyaltyPlanId' }) }
                           }
                           render={({ field: { value, onChange } }) => (
                              <Select
                                 label={intl.formatMessage({ id: 'partner.operation.plan' })}
                                 value={value}
                                 onChange={(i: string) => {
                                    onChange(i)
                                    setValue('operation.serviceType', '', { shouldValidate: true })
                                 }}
                                 disabled={isEdit}
                                 error={errors?.operation?.loyaltyPlanId?.message}
                                 showErrorText={true}
                                 isTouched={dirtyFields?.operation?.loyaltyPlanId}
                              >
                                 <Select.Option value="">--</Select.Option>
                                 {plans.map((plan) => (
                                    <Select.Option key={plan.id} value={plan.id}>
                                       {plan.name}
                                    </Select.Option>
                                 ))}
                              </Select>
                           )}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Controller
                           name="operation.operationType"
                           control={control}
                           rules={
                              isEdit
                                 ? undefined
                                 : { required: intl.formatMessage({ id: 'operation.errors.operationType' }) }
                           }
                           render={({ field: { value, onChange } }) => (
                              <Select
                                 label={intl.formatMessage({ id: 'partner.operation.type' })}
                                 value={value}
                                 onChange={(i: string) => {
                                    onChange(i)
                                    setValue('operation.serviceType', '', { shouldValidate: true })
                                 }}
                                 disabled={isEdit}
                                 error={errors?.operation?.operationType?.message}
                                 showErrorText={true}
                                 isTouched={dirtyFields?.operation?.operationType}
                              >
                                 <Select.Option value="">--</Select.Option>
                                 {['ACCUMULATION', 'REDEMPTION'].map((type) => (
                                    <Select.Option key={type} value={type}>
                                       {intl.formatMessage({ id: `operation.form.operationType.${type}` })}
                                    </Select.Option>
                                 ))}
                              </Select>
                           )}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Controller
                           name="operation.serviceType"
                           control={control}
                           rules={
                              isEdit
                                 ? undefined
                                 : { required: intl.formatMessage({ id: 'operation.form.errors.serviceId' }) }
                           }
                           render={({ field: { value, onChange } }) => (
                              <Select
                                 label={intl.formatMessage({ id: 'partner.operation.service' })}
                                 value={value}
                                 onChange={(value: string) => onChange(value ? Number(value) : '')}
                                 error={errors?.operation?.serviceType?.message}
                                 showErrorText={true}
                                 className="mb-4"
                                 isTouched={dirtyFields.operation?.serviceType}
                                 disabled={isEdit || loadingServiceTypes}
                              >
                                 <Select.Option value="">--</Select.Option>
                                 {serviceTypes?.map((type: any) => (
                                    <Select.Option key={type.id} value={type.id}>
                                       {type.description}
                                    </Select.Option>
                                 ))}
                              </Select>
                           )}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Controller
                           name={'operation.registationChannel'}
                           control={control}
                           rules={
                              isEdit
                                 ? undefined
                                 : { required: intl.formatMessage({ id: 'operations.form.error.registationChannel' }) }
                           }
                           render={({ field: { value, onChange } }) => (
                              <Select
                                 label={intl.formatMessage({ id: 'partner.operation.channel' })}
                                 value={value}
                                 disabled={isEdit}
                                 onChange={(i: string) => {
                                    onChange(i)
                                 }}
                                 error={errors?.operation?.registationChannel?.message}
                                 showErrorText={true}
                                 isTouched={dirtyFields?.operation?.registationChannel}
                              >
                                 <Select.Option value="">--</Select.Option>
                                 {channels?.map((type) => (
                                    <Select.Option key={type.id} value={type.id}>
                                       {type.name}
                                    </Select.Option>
                                 ))}
                              </Select>
                           )}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Input
                           label={intl.formatMessage({ id: 'partner.operation.nights' })}
                           type="number"
                           {...register('operation.numberOfNights')}
                           disabled={isEdit}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Input
                           label={intl.formatMessage({ id: 'partner.operation.rooms' })}
                           type="text"
                           {...register('operation.roomsPerNight')}
                           disabled={isEdit}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Controller
                           name="operation.operationDate"
                           control={control}
                           rules={
                              isEdit
                                 ? undefined
                                 : { required: intl.formatMessage({ id: 'operation.errors.operationDate' }) }
                           }
                           render={({ field }) => {
                              return (
                                 <div
                                    className={cx({
                                       'has-error': errors?.operation?.operationDate?.message,
                                       'has-success':
                                          !errors?.operation?.operationDate?.message &&
                                          dirtyFields?.operation?.operationDate,
                                    })}
                                 >
                                    <label className="block mb-2 font-semibold">
                                       {intl.formatMessage({ id: 'partner.operation.operation.date' })}
                                    </label>
                                    <DatePicker
                                       placeholderText="Seleccionar fecha"
                                       closeOnScroll
                                       onChange={(date) => field.onChange(date)}
                                       onBlur={field.onBlur}
                                       selected={field.value as unknown as Date}
                                       dateFormat="dd/MM/yyyy"
                                       showIcon
                                       isClearable
                                       maxDate={new Date()}
                                       className="w-full border border-gray-300 rounded-md p-2 form-control"
                                       wrapperClassName="w-full"
                                       disabled={isEdit}
                                    />
                                    {errors?.operation?.operationDate?.message && (
                                       <div className="error-block text-[12px] mt-1">
                                          {errors?.operation?.operationDate?.message}
                                       </div>
                                    )}
                                 </div>
                              )
                           }}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Controller
                           name="operation.reservationDate"
                           control={control}
                           rules={
                              isEdit ? undefined : { required: intl.formatMessage({ id: 'operation.errors.stayDate' }) }
                           }
                           render={({ field }) => (
                              <div
                                 className={cx({
                                    'has-error': errors?.operation?.reservationDate?.message,
                                    'has-success':
                                       !errors?.operation?.reservationDate?.message &&
                                       dirtyFields?.operation?.reservationDate,
                                 })}
                              >
                                 <label className="block mb-2 font-semibold">
                                    {intl.formatMessage({ id: 'partner.operation.service.date' })}
                                 </label>
                                 <DatePicker
                                    placeholderText="Seleccionar fecha"
                                    closeOnScroll
                                    onChange={(date) => field.onChange(date)}
                                    onBlur={field.onBlur}
                                    selected={field.value as unknown as Date}
                                    dateFormat="dd/MM/yyyy"
                                    showIcon
                                    isClearable
                                    maxDate={new Date()}
                                    className="w-full border border-gray-300 rounded-md p-2 form-control"
                                    wrapperClassName="w-full"
                                    disabled={isEdit}
                                 />
                                 {errors?.operation?.reservationDate?.message && (
                                    <div className="error-block text-[12px] mt-1">
                                       {errors?.operation?.reservationDate?.message}
                                    </div>
                                 )}
                              </div>
                           )}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Input
                           label={intl.formatMessage({ id: 'partner.operation.invoice' })}
                           type="text"
                           {...register('operation.invoiceNumber', {
                              required: intl.formatMessage({ id: 'partner.operation.invoice.required' }),
                           })}
                           error={errors?.operation?.invoiceNumber?.message}
                           isTouched={dirtyFields?.operation?.invoiceNumber}
                        />
                     }
                  />
                  <CardItem
                     value={
                        <Input
                           label={intl.formatMessage({ id: 'partner.operation.reference' })}
                           type="text"
                           {...register('operation.reference', {
                              required: intl.formatMessage({ id: 'partner.operation.reference.required' }),
                           })}
                           error={errors?.operation?.reference?.message}
                           isTouched={dirtyFields?.operation?.reference}
                        />
                     }
                  />
               </Card.Body>
               <Card.Footer>
                  <Button
                     type="submit"
                     bsStyle="default"
                     buttonStyle={{
                        paddingRight: '25px',
                        paddingLeft: '25px',
                        color: 'white',
                        backgroundColor: 'rgb(217 119 6 / var(--tw-bg-opacity))',
                     }}
                     disabled={isPending}
                  >
                     {intl.formatMessage({ id: 'partner.operation.save' })}
                  </Button>
                  <Button className="ml-4" type="button" bsStyle="default" onClick={onCancel} disabled={isPending}>
                     {intl.formatMessage({ id: 'partner.operation.cancel' })}
                  </Button>
               </Card.Footer>
            </form>
         </Card>
      </>
   )
}

export default Form
