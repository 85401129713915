export const QUERY_KEYS = {
   GET_PROGRAMS: 'GET_PROGRAMS',
   GET_PROGRAM: 'GET_PROGRAM',
   GET_PROGRAM_FILTER: 'GET_PROGRAM_FILTER',
   GET_CHANNEL_LIST: 'GET_CHANNEL_LIST',
   GET_LEVEL_LIST: 'GET_LEVEL_LIST',
   GET_PARTNER: 'GET_PARTNER',
   GET_PARTNER_LIST: 'GET_PARTNER_LIST',
   GET_POINTS: 'GET_POINTS',
   GET_PARTNER_HISTORY: 'GET_PARTNER_HISTORY',
   GET_OPERATION_LIST: 'GET_OPERATION_LIST',
   GET_PROFILE: 'GET_PROFILE',
   BASE: 'BASE',
   REFRESH_TOKEN: 'REFRESH_TOKEN',
   GET_HOTEL_LIST: 'GET_HOTEL_LIST',
   GET_CONTRACT_LIST: 'GET_CONTRACT_LIST',
   GET_OPERATION_STATUS: 'GET_OPERATION_STATUS',
   GET_OPERATION: 'GET_OPERATION',
   GET_SERVICE_TYPE_LIST: 'GET_SERVICE_TYPE_LIST',
   GET_INSTRUCTION_TABLE_OPERATION_PMS: 'GET_INSTRUCTION_TABLE_OPERATION_PMS',
   GET_MEMBERS_MOST_INCOME: 'GET_MEMBERS_MOST_INCOME',
   GET_MEMBERS_MOST_POINTS: 'GET_MEMBERS_MOST_POINTS',
   GET_OPERATIONS_RESUME: 'GET_OPERATIONS_RESUME',
   GET_MEMBERS_MOST_ACTIVITY: 'GET_MEMBERS_MOST_ACTIVITY',
   GET_MEMBERS_LESS_ACTIVITY: 'GET_MEMBERS_LESS_ACTIVITY',
} as const
