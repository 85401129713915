import { useMemo, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Amount, Button } from 'ui'
import qs from 'qs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { faArrowsRotate, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import PartnerTemplate from './components/PartnerTemplate'
import useGetPartner from '../../hooks/useGetPartner'
import Card from '../common/Card'
import { getCurrentValues } from '../../utils/operationUtils'
import TableOperations from '../operations/components/TableOperations'
import { useGetProgramFilter } from '../../hooks/useGetProgramFilter'
import { Operation } from '../operations'
import { createDateFromISO } from 'utils/dates'
import { useAppSelector } from '../../store/hooks'
import PartnerOperationsFilters, { PartnerOperationsFilterForm } from './components/PartnerOperationsFilters'
import { useGetOperationsResume } from '../../hooks/useGetOperationsResume'
import { getOperationExportList } from '../../api/operation'
import Breadcrumb from '../../components/common/Breadcrumb'

type Props = {}

const PartnerOperations = (props: Props) => {
   const intl = useIntl()
   const location = useLocation()
   const navigate = useNavigate()
   const { locale } = useAppSelector((state) => state.base)
   const { id, accountCode } = useParams()
   const { data: partner, status: partnerStatus } = useGetPartner(id)
   const { data: programs, isSuccess } = useGetProgramFilter()
   const currentValues = getCurrentValues(location.search)
   const [isExporting, setIsExporting] = useState(false)
   const { mutate: exportFile } = useMutation({
      mutationFn: getOperationExportList,
   })
   let defaultProgram = programs && programs.items ? programs.items.find((program) => program.isDefault)?.id : undefined

   const { data: operationsResume } = useGetOperationsResume({
      memberId: id,
      loyaltyPlanId: defaultProgram,
   })

   const onPageChange = (page: { page: number; size: number }) => {
      const request = Object.assign({}, currentValues, { ...page })
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }

   function onSort(sort: string[]) {}

   const onFilter = (data: PartnerOperationsFilterForm) => {
      const request = Object.assign({}, data)
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      ) //TODO: revisar si se soluciona el bug que encodea la coma https://github.com/ljharb/qs/issues/410
   }

   const columns = useMemo(
      () => [
         {
            header: intl.formatMessage({ id: 'operations.table.operation.id' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <Link to={`/${accountCode}/partners/${row.original.member.id}/operation/${row.original.id}`}>
                     {row.original.id ? row.original.importId : '--'}
                  </Link>
               )
            },
            enableSorting: false,
         },
         {
            header: () => (
               <div className="text-right"> {intl.formatMessage({ id: 'operations.table.partner.amount' })}</div>
            ),
            accessorKey: 'amount',
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div className="text-right">
                     {row.original.amount ? (
                        <Amount amount={row.original.amount} minimumFractionDigits={2} maximumFractionDigits={2} />
                     ) : (
                        '--'
                     )}
                  </div>
               )
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.generic.type' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div>
                     <div>{row.original.serviceType}</div>
                     {row.original.reservationCode ? (
                        <Link
                           to={`${process.env.REACT_APP_CRS_URL}/#/${accountCode}/itineraries/${row.original.reservationUuid}`}
                           className="text-blue-600 dark:text-blue-500 hover:underline text-sm"
                           target="_blank"
                        >
                           {row.original.reservationCode}
                        </Link>
                     ) : null}
                  </div>
               )
            },
            enableSorting: false,
         },
         {
            header: () => <div className="text-center">{intl.formatMessage({ id: 'operations.table.type' })}</div>,
            accessorKey: 'operationType',
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div>
                     {row.original.operationType
                        ? intl.formatMessage({ id: `operations.table.type.${row.original.operationType}` })
                        : '--'}
                     <div className="text-sm text-neutral-400">
                        {row.original.operationStatus
                           ? intl.formatMessage({ id: `operations.table.type.${row.original.operationStatus}` })
                           : '--'}
                     </div>
                  </div>
               )
            },
            enableSorting: false,
         },

         {
            header: () => <div className="text-right">{intl.formatMessage({ id: 'operations.table.points' })}</div>,
            accessorKey: 'accumulatedPoints',
            cell: ({ row }: { row: { original: Operation } }) => {
               const typeOfPoints = row.original?.operationType ? (
                  row.original.operationType === 'REDEMPTION' ? (
                     <div className="text-right text-red-600 font-semibold"> {`-${row.original.pointsRedeemed}`} </div>
                  ) : (
                     <div className="text-right text-green-600 font-semibold">{`+${row.original.pointsAccumulated}`}</div>
                  )
               ) : null
               return typeOfPoints ? typeOfPoints : '--'
            },
            enableSorting: true,
         },

         {
            header: () => <div className="text-center">{intl.formatMessage({ id: 'operations.table.CN' })}</div>,
            accessorKey: 'roomNights',
            cell: ({ row }: { row: { original: Operation } }) => {
               return <div className="text-center">{row.original.roomNights}</div>
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.date.stay' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               const isoDate = createDateFromISO(row.original.reservationDate).setLocale(locale)
               return <div>{isoDate.isValid ? isoDate.toFormat(intl.formatMessage({ id: 'date.format' })) : '--'}</div>
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.date.application' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               const isoDate = createDateFromISO(row.original.applicationDate).setLocale(locale)
               return (
                  <div>
                     {isoDate.toFormat(intl.formatMessage({ id: 'date.format' }))}
                     <div className="text-sm text-neutral-400">{`${isoDate.toFormat('h:mm ')} hrs`}</div>
                  </div>
               )
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.registrationChannel' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return <div>{row.original.channel}</div>
            },
            enableSorting: false,
         },
      ],
      [intl, locale, accountCode],
   )

   const onExport = () => {
      const { loyaltyPlanIds, query } = currentValues
      const toastId = toast.loading('Generando archivo espere un momento...', { autoClose: false })

      let request = {
         account: accountCode || '',
         emailRecipients: [],
         filters: {
            ...(query ? { query: query } : {}),
            ...(loyaltyPlanIds.length ? { loyaltyPlanId: Number(loyaltyPlanIds) } : { loyaltyPlanId: defaultProgram }),
            memberId: id || '',
         },
      }
      setIsExporting(true)
      exportFile(request, {
         onSuccess: (response) => {
            let excellName = `operations.xlsx`
            const blob = new Blob([response], {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = excellName
            document.body.appendChild(link)
            toast.update(toastId, {
               render: 'Exportación completada 🎉',
               type: 'success',
               isLoading: false,
               autoClose: 3000,
               position: 'bottom-center',
            })
            link.click()
            document.body.removeChild(link)
            setIsExporting(false)
         },
         onError: (error) => {
            setIsExporting(false)
            toast.update(toastId, {
               render: 'Error en la exportación 😢',
               type: 'error',
               isLoading: false,
               autoClose: 3000,
            })
         },
      })
   }

   const breadcrumb = [
      {
         url: `/${accountCode}/partners/`,
         label: intl.formatMessage({ id: 'partner.operation.members' }),
      },
      {
         label: partner?.externalId?.toString(),
      },
   ]

   return (
      <>
         <Breadcrumb items={breadcrumb} />
         <PartnerTemplate
            partner={partner}
            partnerStatus={partnerStatus}
            accountCode={accountCode}
            id={id}
            activeTabCode="operations"
         >
            <div className="flex flex-col gap-y-4 mx-4">
               {isSuccess && partner && programs.items && programs.items.length > 0 && (
                  <>
                     <PartnerOperationsFilters
                        defaultProgram={defaultProgram}
                        onSubmit={onFilter}
                        className="grid grid-flow-col auto-cols-6 gap-4 "
                     >
                        {operationsResume ? (
                           <>
                              <div className="overflow-x-auto">
                                 <div className="text-sm truncate">
                                    {intl.formatMessage({ id: 'operations.pointsAvailable' })}
                                 </div>
                                 <Amount
                                    amount={operationsResume.pointsAvailable}
                                    showCurrency={false}
                                    showCurrencySign={false}
                                    className="text-gray-500 font-semibold"
                                 />
                              </div>
                              <div>
                                 <div className="text-sm truncate">
                                    {intl.formatMessage({ id: 'operations.pointsAccumulated' })}
                                 </div>
                                 <Amount
                                    amount={operationsResume.pointsAccumulated}
                                    showCurrency={false}
                                    showCurrencySign={false}
                                    className="text-gray-500 font-semibold"
                                 />
                              </div>
                              <div>
                                 <div className="text-sm truncate">
                                    {intl.formatMessage({ id: 'operations.pointsRedeemed' })}
                                 </div>
                                 <Amount
                                    amount={operationsResume.pointsRedeemed}
                                    showCurrency={false}
                                    showCurrencySign={false}
                                    className="text-gray-500 font-semibold"
                                 />
                              </div>
                              <div>
                                 <div className="text-sm truncate">
                                    {intl.formatMessage({ id: 'operations.totalAmount' })}
                                 </div>
                                 <Amount
                                    amount={operationsResume.totalAmount}
                                    currency={operationsResume.currency}
                                    className="text-gray-500 font-semibold"
                                 />
                              </div>
                              <div>
                                 <div className="text-sm truncate">
                                    {intl.formatMessage({ id: 'operations.roomNights' })}
                                 </div>
                                 <Amount
                                    amount={operationsResume.roomNights}
                                    showCurrency={false}
                                    showCurrencySign={false}
                                    className="text-gray-500 font-semibold"
                                 />
                              </div>
                           </>
                        ) : null}
                     </PartnerOperationsFilters>
                     <Card>
                        <TableOperations
                           currentValues={currentValues}
                           onPageChange={onPageChange}
                           columns={columns}
                           defaultProgram={defaultProgram}
                           defaultQuery={partner.email}
                           onSortChange={onSort}
                           contanierClassName="mt-4"
                        >
                           <div className="flex justify-between gap-x-2">
                              <Button
                                 onClick={onExport}
                                 disabled={isExporting}
                                 className={'flex gap-x-2 items-center text-[12px]'}
                              >
                                 {isExporting ? (
                                    <FontAwesomeIcon icon={faArrowsRotate} className={'text-gray-500 text '} />
                                 ) : (
                                    <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                                 )}
                                 {intl.formatMessage({ id: 'operations.export' })}
                              </Button>
                           </div>
                        </TableOperations>
                     </Card>
                  </>
               )}
            </div>
         </PartnerTemplate>
      </>
   )
}

export default PartnerOperations
