import qs from 'qs'
import { Country } from '../api/countries'
import { uniqBy } from 'utils'
import { alertTypes } from 'ui'

export type PartnerRow = {
   id: number
   firstName: string
   lastName: string
   email: string
   level: string
   registrationChannel: string
   points: number
   income: number
   lastOperationDate: string
   loyaltyPlans: string[]
   externalId: number
   totalRevenue: number
   roomNights: number
}

type Request = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: string
   channelIds: number[]
   levelIds: number[]
   active?: string
}

export function getCurrentValues(search: string, defaultPlan?: number): Request {
   const result = qs.parse(search, {
      comma: false,
      ignoreQueryPrefix: true,
   }) as {
      query?: string
      page?: string
      size?: string
      sort?: string
      loyaltyPlanIds?: string
      channelIds?: string
      levelIds?: string
      active?: string
   }
   return {
      query: result.query || '',
      page: parseInt(result.page || '0'),
      size: parseInt(result.size || '20'),
      loyaltyPlanIds: result.loyaltyPlanIds ? result.loyaltyPlanIds : defaultPlan ? defaultPlan.toString() : '',
      channelIds: result.channelIds ? result.channelIds.split(',').map(Number) : [],
      sort: result.sort ? result.sort.split(',') : [],
      levelIds: result.levelIds ? result.levelIds.split(',').map(Number) : [],
      ...(result.active !== 'Todos' && {
         active: result.active,
      }),
   } as Request
}
export function getPhonesOptions(countries: Array<Country>) {
   const mapper = (country: Country) => {
      return {
         code: country.code,
         value: `${country.name} +${country.phoneCode}`,
         disabled: country.disabled || false,
      }
   }

   return mapCountryOptios(countries, mapper)
}
function mapCountryOptios(countries: Array<Country>, mapper: (country: Country) => unknown, uniqByValue?: string) {
   let sorted = countries.sort((a, b) => {
      if (a.order_ !== null && a.order_ !== undefined && (b.order_ === null || b.order_ === undefined)) {
         return -1
      }
      if (b.order_ !== null && b.order_ !== undefined && (a.order_ === null || a.order_ === undefined)) {
         return 1
      }
      if (b.order_ !== null && b.order_ !== undefined && a.order_ !== null && a.order_ !== undefined) {
         return a.order_ > b.order_ ? 1 : -1
      }
      return 0
   })
   if (uniqByValue) {
      sorted = uniqBy(sorted, uniqByValue)
   }
   const options = sorted.map(mapper)

   const withOrder = countries.filter((country) => country.order_ !== null)

   if (withOrder.length > 0) {
      options.splice(withOrder.length, 0, {
         code: '',
         value: '------------------',
         disabled: true,
      })
   }
   return options
}
export function getCountryPhoneCode(countries: Array<Country>, code?: string) {
   if (!code) {
      return ''
   }
   const country = countries.find((country) => country.code === code)
   return country ? `${country.code} +${country.phoneCode}` : ''
}
export function getStatusOptions(statuses: Array<string>) {
   return statuses.map((status) => ({
      code: status,
      value: status.toLowerCase(),
   }))
}

export function getAlertType(status: string) {
   switch (status) {
      case 'PENDING':
         return alertTypes.GENERIC_WARNING
      case 'IN_PROGRESS':
         return alertTypes.GENERIC_INFO
      case 'COMPLETED':
         return alertTypes.GENERIC_SUCCESS
      default:
         return alertTypes.GENERIC_DANGER
   }
}
