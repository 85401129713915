import { Link, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export const ErrorPage = () => {
   const { accountCode } = useParams()

   return (
      <section>
         <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-sm text-center">
               <div className="mb-4 text-7xl text-primary-600">
                  <FontAwesomeIcon icon={faTriangleExclamation} size="3x" />
               </div>
               <p className="mb-4 text-3xl tracking-tight font-bold text-primary-600 md:text-4xl">
                  Se ha producido un error al mostrar esta página
               </p>
               <Link
                  to={`/${accountCode}`}
                  className="inline-flex text-white bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
               >
                  Regresar a inicio
               </Link>
            </div>
         </div>
      </section>
   )
}

export default ErrorPage
