import { AxiosProgressEvent } from 'axios'
import { Operation } from '../components/operations'
import { apiCall } from '../utils/api'

export type GetOperationListRequest = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: string
   from?: string
   to?: string
   operationStatus: string[]
   channelIds: number[]
}
export type GetOperationExportListRequest = {
   account: string
   emailRecipients?: Array<string>
   filters: {
      loyaltyPlanId?: number
      memberId?: string
      query?: string
   }
   cb?: (progress: AxiosProgressEvent) => void
}
export type requestCreateOperation = {
   account: string
   memberId: string
   id: string
   loyaltyPlanId: number
   registationChannel: string
   operationType: string
   serviceType: number
   amount: number
   currency: string
   numberOfNights?: number
   roomsPerNight?: number
   invoiceNumber?: string
   reference?: string
}
export type requestEditCreateOperation = {
   id: string
   invoiceNumber?: string
   reference?: string
}

export type getOperationListResponse = {
   items: Array<Operation>
   totalSize: number
}
interface operationImportRequest {
   loyaltyPlanId: number
   file: FormData | File
}
export interface responseImported {
   account: string
   loyaltyPlanId: number
   path: string
   statusId: number
}

export async function getOperationExportList(request: GetOperationExportListRequest): Promise<any> {
   const { cb, ...rest } = request
   let extraHeaders = {
      Accept: '*/* , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
   }
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/export`,
      method: 'POST',
      data: rest,
      headers: extraHeaders,
      responseType: 'arraybuffer',
      onDownloadProgress: (progressEvent) => {
         if (cb) {
            cb(progressEvent)
         }
      },
   })
   return result.data
}
export async function getOperationList(request: GetOperationListRequest): Promise<getOperationListResponse> {
   const { query, page, size, loyaltyPlanIds, from, to, sort, operationStatus } = request
   let params = {}
   if (query) params = { ...params, query }
   if (page >= 0) params = { ...params, page }
   if (size) params = { ...params, size }
   if (loyaltyPlanIds) params = { ...params, loyaltyPlanIds: loyaltyPlanIds }
   if (from) params = { ...params, from: from }
   if (to) params = { ...params, to: to }
   if (operationStatus && operationStatus.length) params = { ...params, operationStatus: operationStatus.join(',') }
   if (sort && sort.length) params = { ...params, sort: sort.join(',') }
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations`,
      method: 'GET',
      params,
   })
   return { items: result.data, totalSize: parseInt(result.headers['x-total-count']) }
}
export async function createOperation(request: Omit<requestCreateOperation, 'id'>): Promise<Operation> {
   const { ...data } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations`,
      method: 'POST',
      data: data,
   })
   return result.data
}
export async function editOperation(request: requestEditCreateOperation): Promise<Operation> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/${request.id}`,
      method: 'PATCH',
      data: request,
   })
   return result.data
}
export async function importFileOperations(request: operationImportRequest): Promise<responseImported> {
   const { loyaltyPlanId, file } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/loyaltyPlan/${loyaltyPlanId}/upload`,
      method: 'POST',
      data: { file: file },
      headers: {
         Accept: 'application/json, text/plain, */*',
         'Content-Type': 'multipart/form-data;',
      },
   })

   return result.data
}
export async function importFilePMSOperations(request: operationImportRequest): Promise<responseImported> {
   const { loyaltyPlanId, file } = request
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/loyaltyPlan/${loyaltyPlanId}/upload/pms`,
      method: 'POST',
      data: { file: file },
      headers: {
         Accept: 'application/json, text/plain, */*',
         'Content-Type': 'multipart/form-data;',
      },
   })

   return result.data
}
export async function getOperationStatus(request: any): Promise<Array<string>> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/status`,
      method: 'GET',
   })

   return result.data
}
export async function getOperation(id?: string): Promise<Operation> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/${id}`,
      method: 'GET',
   })

   return result.data
}
export async function getServiceTypeList(request: any): Promise<any> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/service-types`,
      params: request,
      method: 'GET',
   })
   if (result.data) {
      return Object.entries(result.data).map(([id, description]) => ({
         id: Number(id),
         description,
      }))
   }
   return []
}
export async function getOperationLayoutPMS(request: any): Promise<any> {
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/import/layout`,
      method: 'GET',
      responseType: 'arraybuffer',
   })
   if (result.data) {
      return result.data
   }
   return []
}
export async function getOperationinstructionTablePMS(id?: string): Promise<
   Array<{
      catalogType: string
      values: string
      column: string
      dataType: string
      name: string
   }>
> {
   if (!id) return []
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/loyaltyPlan/${id}/import-details`,
      method: 'GET',
   })
   if (result.data) {
      return result.data
   }
   return []
}

export type GetOperationsResumeRequest = {
   memberId?: string
   loyaltyPlanId?: number
   dates?: {
      from?: string
      to?: string
   }
   operationStatus?: Array<string>
   channelIds?: Array<number>
   query?: string
}

export type OperationsResume = {
   pointsAvailable: number
   pointsAccumulated: number
   pointsRedeemed: number
   totalAmount: number
   currency: string
   roomNights: number
   levelName: string
}

export async function getOperationsResume(request: GetOperationsResumeRequest): Promise<OperationsResume | null> {
   const { query, loyaltyPlanId, dates, operationStatus, memberId } = request

   let params = {}
   if (operationStatus && operationStatus.length) params = { ...params, operationStatus: operationStatus.join(',') }
   if (query) params = { ...params, query }
   if (loyaltyPlanId) params = { ...params, loyaltyPlanId }
   if (dates?.from) params = { ...params, from: dates.from }
   if (dates?.to) params = { ...params, to: dates.to }
   if (memberId) params = { ...params, memberId }

   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/operations/resume`,
      method: 'GET',
      params: params,
   })
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }

   return result.data
}
