// eslint-disable-next-line
export default {
   'dashboard.accumulatedPoints': 'Puntos acumulados',
   'dashboard.redeemedPoints': 'Puntos redimidos',
   'dashboard.activations': 'Activaciones',
   'dashboard.periodActivations': 'Activaciones en el periodo',
   'dashboard.apliedAndRedeemedPoints': 'Puntos aplicados y redimidos',
   'dashboard.activationsPerChannel': 'Activaciones por canal',
   'dashboard.membershipsPerLevel': 'Membresías por nivel',
   'dashboard.redemptions': 'Redenciones',
   'dashboard.applications': 'Aplicaciones',
   'dashboard.dateFormat': 'dd LLL.',
   'dashboard.registers': 'Inscripciones',
   'dashboard.table.members.name': 'Nombre',
   'dashboard.table.members.id': 'Id',
   'dashboard.table.members.points': 'Puntos',
   'dashboard.table.members.income': 'Ingresos',
   'dashboard.table.members.lastActivity': 'Ultima actividad',
   'dashboard.table.members.tittle.mostIncome': 'Socios con más ingresos',
   'dashboard.table.members.tittle.points': 'Socios con más puntos',
   'dashboard.table.members.tittle.most.Activity': 'Socios con actividad más reciente',
   'dashboard.table.members.tittle.less.Activity': 'Socios con actividad menos reciente',
   'dashboard.table.members.showAll': 'Ver todos',
}
