import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '../../constants/queryKeys'
import { getOperationsResume, GetOperationsResumeRequest } from '../../api/operation'

function useGetOperationsResume(request: GetOperationsResumeRequest) {
   return useQuery({
      queryKey: [QUERY_KEYS.GET_OPERATIONS_RESUME, request],
      queryFn: () => {
         return getOperationsResume(request)
      },
      enabled: !!request.loyaltyPlanId,
   })
}
export { useGetOperationsResume }
export default useGetOperationsResume
