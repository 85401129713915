import classNames from 'classnames'
import React from 'react'

type Props = {
   className?: string
   children?: React.ReactNode
}

const Card = ({ children, className }: Props) => {
   return <div className={classNames('border bg-white rounded-sm mb-8', className)}>{children}</div>
}
const Title = ({ children, className }: Props) => {
   return <div className={classNames('border-b px-4 py-3 text-xl', className)}>{children}</div>
}

const Body = ({ children, className }: Props) => {
   return <div className={classNames('px-4 py-3', className)}>{children}</div>
}

const Footer = ({ children, className }: Props) => {
   return <div className={classNames('border-t px-4 py-3', className)}>{children}</div>
}

Card.Title = Title
Card.Body = Body
Card.Footer = Footer

export default Card
