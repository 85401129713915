import classNames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
   label?: string
   value?: ReactNode | ReactNode[]
   className?: string
}

const CardItem = ({ label, value, className = '' }: Props) => {
   return (
      <div className={classNames('flex flex-col gap-y-1', className)}>
         <div className="overflow-hidden inline-block flex-1">
            {label ? <label className="font-semibold text-neutral-400">{label}</label> : null}
            <div>{value}</div>
         </div>
      </div>
   )
}

export default CardItem
