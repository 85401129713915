import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { TextSpinner, Amount } from 'ui'
import { createDateFromISO } from 'utils/dates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'

import { useAppSelector } from '../../../../../store/hooks'
import { useGetOperation } from '../../../../../hooks/useGetOperation'
import useGetPartner from '../../../../../hooks/useGetPartner'
import useGetPrograms from '../../../../../hooks/useGetPrograms'
import Card from '../../../../common/Card'
import CardItem from '../../common/CardItem'
import Breadcrumb from '../../../../common/Breadcrumb'
import ErrorPage from '../../../../common/ErrorPage'

export const OperationDetails = () => {
   const intl = useIntl()
   const { locale } = useAppSelector((state) => state.base)
   const { id, operationId, accountCode } = useParams()
   const { data: operation, status: operationStatus, error } = useGetOperation(operationId)
   const { data: partner } = useGetPartner(id)
   const { data: plans } = useGetPrograms()

   if (error) {
      return <ErrorPage />
   }

   const plan = plans?.items?.find((plan) => plan.id === operation?.loyaltyPlanId)

   const breadcrumb = [
      {
         url: `/${accountCode}/partners/`,
         label: intl.formatMessage({ id: 'partner.operation.members' }),
      },
      {
         url: `/${accountCode}/partners/${operation?.member?.id}`,
         label: partner?.externalId?.toString(),
      },
      {
         url: `/${accountCode}/partners/${operation?.member?.id}/operations`,
         label: intl.formatMessage({ id: 'partner.operation.operations' }),
      },
      {
         label: intl.formatMessage({ id: 'partner.operation.operation' }, { operation: operation?.importId }),
      },
   ]

   return (
      <>
         {operation ? <Breadcrumb items={breadcrumb} /> : null}
         <div className="flex justify-between m-4">
            <div>
               <h2 className="text-3xl mb-1">
                  {operationStatus === 'success' ? (
                     intl.formatMessage({ id: 'partner.operation.operation' }, { operation: operation?.importId })
                  ) : (
                     <TextSpinner
                        style={{
                           width: '100%',
                           color: 'transparent',
                           lineHeight: '1.0667em',
                        }}
                     >
                        .
                     </TextSpinner>
                  )}
               </h2>

               <div className="text-neutral-400">
                  {operation?.operationDate
                     ? intl.formatMessage(
                          { id: 'partner.operation.from' },
                          {
                             date: createDateFromISO(operation?.operationDate)
                                .setLocale(locale)
                                .toFormat('EEEE, dd MMMM yyyy, HH:mm'),
                          },
                       )
                     : null}
               </div>
            </div>
         </div>
         <div className="flex flex-col md:flex-row gap-x-4 mx-4">
            {operation ? (
               <div className="basis-8/12">
                  <Card>
                     <Card.Title className="flex justify-between">
                        <span>{intl.formatMessage({ id: 'partner.operation.details' })}</span>
                        <Menu
                           menuButton={
                              <MenuButton className="w-[30px] h-[30px] rounded-full hover:bg-gray-100">
                                 <FontAwesomeIcon icon={faEllipsisVertical} />
                              </MenuButton>
                           }
                           transition
                           align="end"
                        >
                           <MenuItem>
                              <Link
                                 to={`/${accountCode}/partners/${partner?.id}/operation/${operation?.id}/edit`}
                                 className="text-sm"
                              >
                                 {intl.formatMessage({ id: 'partner.operation.edit' })}
                              </Link>
                           </MenuItem>
                        </Menu>
                     </Card.Title>
                     <Card.Body className="px-4 pb-6 relative">
                        <div className="grid grid-cols-2 gap-4">
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.operation.amount' })}
                              value={
                                 <Amount
                                    amount={operation.amount}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                 />
                              }
                           />
                           <CardItem label={''} value={''} />
                           <CardItem label={intl.formatMessage({ id: 'partner.operation.plan' })} value={plan?.name} />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.operation.type' })}
                              value={intl.formatMessage({ id: `operations.table.type.${operation.operationType}` })}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.operation.service' })}
                              value={operation.serviceTypeName || '--'}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.operation.channel' })}
                              value={operation.registrationChannelName ?? '--'}
                           />
                           {operation.numberOfNights !== null && operation.numberOfNights !== undefined ? (
                              <CardItem
                                 label={intl.formatMessage({ id: 'partner.operation.nights' })}
                                 value={operation.numberOfNights}
                              />
                           ) : null}
                           {operation.roomsPerNight !== null && operation.roomsPerNight !== undefined ? (
                              <CardItem
                                 label={intl.formatMessage({ id: 'partner.operation.rooms' })}
                                 value={operation.roomsPerNight}
                              />
                           ) : null}
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.operation.operation.date' })}
                              value={createDateFromISO(operation?.createdAt)
                                 .setLocale(locale)
                                 .toFormat('EEE. dd MMMM yyyy')}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.operation.service.date' })}
                              value={createDateFromISO(operation?.reservationDate)
                                 .setLocale(locale)
                                 .toFormat('EEE. dd MMMM yyyy')}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.operation.invoice' })}
                              value={operation.invoiceNumber || '--'}
                           />
                           <CardItem
                              label={intl.formatMessage({ id: 'partner.operation.reference' })}
                              value={operation.reference || '--'}
                           />
                        </div>
                     </Card.Body>
                  </Card>
               </div>
            ) : (
               <div className="absolute inset-0 bg-neutral-100 opacity-25 flex items-center justify-center">
                  <FontAwesomeIcon icon={faSpinner} spin size="3x" />
               </div>
            )}
            <div className="basis-4/12">
               {partner && operation ? (
                  <Card className="mb-4">
                     <Card.Title>{intl.formatMessage({ id: 'partner.operation.member' })}</Card.Title>
                     <Card.Body>
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.operation.member.name' })}
                           value={`${partner?.firstName} ${partner?.lastName}`}
                        />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.operation.member.id' })}
                           value={partner?.externalId}
                        />
                     </Card.Body>
                     <Card.Footer>
                        <Link
                           to={`/${accountCode}/partners/${partner?.id}`}
                           className="text-blue-600 dark:text-blue-500 hover:underline"
                        >
                           {intl.formatMessage({ id: 'partner.operation.member.view.details' })}
                        </Link>
                     </Card.Footer>
                  </Card>
               ) : null}
               {operation?.hotelName && operation?.reservationCode ? (
                  <Card className="mb-4">
                     <Card.Title>{intl.formatMessage({ id: 'partner.operation.reservation' })}</Card.Title>
                     <Card.Body>
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.operation.hotel' })}
                           value={operation.hotelName}
                        />
                        <CardItem
                           label={intl.formatMessage({ id: 'partner.operation.code' })}
                           value={operation.reservationCode}
                        />
                     </Card.Body>
                     <Card.Footer>
                        <Link
                           to={`${process.env.REACT_APP_CRS_URL}/#/${accountCode}/itineraries/${operation.reservationUuid}`}
                           className="text-blue-600 dark:text-blue-500 hover:underline"
                           target="_blank"
                        >
                           {intl.formatMessage({ id: 'partner.operation.member.view.reservation' })}
                        </Link>
                     </Card.Footer>
                  </Card>
               ) : null}
            </div>
         </div>
      </>
   )
}

export default OperationDetails
