import { LoyaltyPlan } from '../components/partners'
import { apiCall } from '../utils/api'
export type GetProgramListRequest = {
   query?: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds?: number[]
   channelIds: number[]
   levelIds: number[]
}

export type GetProgramListExportRequest = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: number[]
   channelIds: number[]
   levelIds: number[]
}
type FieldType = 'String' | 'select' | 'boolean'
interface AdditionalInformationBase {
   label: string
   name: string
   required: boolean
   type: FieldType
}
interface AdditionalInformationWithValues extends AdditionalInformationBase {
   type: 'select' | 'boolean'
   values: string[]
}

interface AdditionalInformationWithoutValues extends AdditionalInformationBase {
   type: 'String'
   values: null
}
export type AdditionalInformationType = AdditionalInformationWithValues | AdditionalInformationWithoutValues
export type plans = {
   id: number
   name: string
   order: number
   hasPointBenefit: boolean
   isDefault: boolean
   additionalInformationTypes: AdditionalInformationType[]
}

export async function getProgramExportList(request?: GetProgramListExportRequest): Promise<{ items: any }> {
   let params = {}
   if (request) {
      const { query, page, size, loyaltyPlanIds, channelIds, levelIds } = request
      if (query) params = { ...params, query }
      if (page >= 0) params = { ...params, page }
      if (size) params = { ...params, size }
      if (loyaltyPlanIds.length) params = { ...params, loyaltyPlanIds: loyaltyPlanIds.join(',') }
      if (channelIds.length) params = { ...params, channelIds: channelIds.join(',') }
      if (levelIds.length) params = { ...params, levelIds: levelIds.join(',') }
   }
   let extraHeaders = {
      Accept: '*/* , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
   }
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/plans/export`,
      method: 'GET',
      params,
      headers: extraHeaders,
      responseType: 'arraybuffer',
   })
   if (result.status !== 200) {
      throw new Error(`error ${result.status}`)
   }
   return result.data
}

export async function getProgramList(
   request?: GetProgramListRequest,
): Promise<{ items: Array<plans>; totalSize: number }> {
   let params = {}
   if (request) {
      const { query, page, size, loyaltyPlanIds, channelIds, levelIds } = request
      if (query) params = { ...params, query }
      if (page >= 0) params = { ...params, page }
      if (size) params = { ...params, size }
      if (loyaltyPlanIds && loyaltyPlanIds.length) params = { ...params, loyaltyPlanIds: loyaltyPlanIds.join(',') }
      if (channelIds.length) params = { ...params, channelIds: channelIds.join(',') }
      if (levelIds.length) params = { ...params, levelIds: levelIds.join(',') }
   }
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/plans`,
      method: 'GET',
      params,
   })

   return { items: result.data, totalSize: parseInt(result.headers['x-total-count']) }
}
export async function getProgram(id: number): Promise<LoyaltyPlan> {
   const result = (await new Promise((resolve) => {
      setTimeout(() => {
         const program = {
            id: 1,
            name: 'Brisas siente +',
            level: [
               {
                  id: 1,
                  name: 'Gold',
               },
            ],
            isActive: true,
            hasDiscount: true,
            primaryColor: '#00d084',
            headerBackground: '#f78da7',
            headerFontColor: '#000000',
            hotels: [
               {
                  id: 1,
                  hotelId: 9,
                  hotelName: 'Hotel Flamingos',
                  discount: 5,
                  contracts: [1],
               },
               {
                  id: 13,
                  hotelId: 39,
                  hotelName: 'Hotel Laguna',
                  discount: 5,
                  contracts: [52, 54],
               },
            ],
            photos: [
               {
                  id: 90,
                  basePath: 'https://res.cloudinary.com/itermotus/hotel-stage/',
                  path: 'ixtul/null/c9d660b9-2-er-logo.png',
                  title: 'er-logo.png',
                  galleryType: 'LOYALTY_LOGO',
               },
               {
                  id: 118,
                  basePath: 'https://res.cloudinary.com/itermotus/hotel-stage/',
                  path: 'ixtul/5/1d86131d-2-After_sport_14.jpg',
                  title: 'After_sport_14.jpg',
                  galleryType: 'LOYALTY_MAIN',
               },
            ],
            benefits: [
               {
                  id: 5,
                  hotels: [1, 5],
                  name: 'Bebida de cortesía',
               },
               {
                  id: 6,
                  hotels: [1],
                  name: 'asdasd',
               },
            ],
         } as LoyaltyPlan
         resolve(program)
      }, 100)
   })) as LoyaltyPlan
   return result
}
