import qs from 'qs'
import { Operation } from '../components/operations'

export type OperationRow = {
   id: number
   reservationName: string
   reservationCode: string
   date: string
   partner: partnerItemOnOperation
   accumulatedPoints: number
   redeemedPoints: number
}
export type partnerItemOnOperation = {
   id: string
   fullName: string
   email: string
   externalId: string
   firstName: string
   lastName: string
}
export function toTableItem(operation: Operation): OperationRow {
   return {
      id: operation.id,
      reservationName: operation.reservationName,
      reservationCode: operation.reservationCode,
      date: operation.reservationDate,
      partner: operation.member,
      accumulatedPoints: operation.pointsAccumulated || 0,
      redeemedPoints: operation.pointsRedeemed || 0,
   }
}

type Request = {
   query: string
   page: number
   size: number
   sort: string[]
   loyaltyPlanIds: string
   channelIds: number[]
   from?: string
   to?: string
   operationStatus: string[]
}

export function getCurrentValues(search: string, defaultPlan?: number): Request {
   const result = qs.parse(search, {
      ignoreQueryPrefix: true,
      allowDots: true,
   }) as {
      query?: string
      page?: string
      size?: string
      sort?: string
      loyaltyPlanIds?: string
      dates?: any
      operationStatus?: string
      channelIds?: string
   }
   return {
      query: result.query || '',
      page: parseInt(result.page || '0'),
      size: parseInt(result.size || '10'),
      sort: result.sort ? result.sort.split(',') : [],
      operationStatus: result.operationStatus?.length ? result.operationStatus.split(',') : '',
      channelIds: result.channelIds ? result.channelIds.split(',').map(Number) : [],
      loyaltyPlanIds: result.loyaltyPlanIds ? result.loyaltyPlanIds : defaultPlan ? defaultPlan.toString() : '',
      ...(result.dates?.dateType === 'other' && {
         from: result.dates?.startDate,
         to: result.dates?.endDate,
      }),
   } as Request
}
