import { useMemo, useRef, useState } from 'react'
import qs from 'qs'
import Title from '../common/Title'
import { useIntl } from 'react-intl'
import Card from '../common/Card'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCurrentValues, partnerItemOnOperation } from '../../utils/operationUtils'
import { createDateFromISO } from 'utils/dates'
import Filters, { OperationFilterForm } from './components/Filters'
import { getOperationExportList } from '../../api/operation'
import { useMutation } from '@tanstack/react-query'
import { useAppSelector } from '../../store/hooks'
import { useGetProgramFilter } from '../../hooks/useGetProgramFilter'
import TableOperations from './components/TableOperations'
import { toast } from 'react-toastify'
import { Amount, Button } from 'ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate, faDownload, faShareFromSquare } from '@fortawesome/free-solid-svg-icons'
import ResumeOperations from './components/ResumeOperations'

export type Operation = {
   id: number
   reservationName: string
   reservationCode: string
   reservationTotal: number
   reservationDate: string
   currency: string
   points: number
   pointsAccumulated: number | null
   pointsRedeemed: number | null
   member: partnerItemOnOperation
   operationType: string
   operationStatus: string
   loyaltyPlanId: number
   levelName: string
   serviceType: string
   serviceTypeName: string
   importId: string
   roomNights: number
   channel: string
   applicationDate: string
   createdAt: string
   amount: number
   isCertificate: boolean
   certificateDetail?: string
   registrationChannelName?: number
   registrationChannel?: string
   roomsPerNight?: number
   numberOfNights?: number
   reference?: string
   invoiceNumber?: string
   hotelName?: string
   reservationUuid?: string
   registationChannel?: number
   operationDate: string
}

type Props = {}

export const Operations = (props: Props) => {
   const intl = useIntl()
   const { locale } = useAppSelector((state) => state.base)
   const navigate = useNavigate()
   const location = useLocation()
   const { accountCode } = useParams<{ accountCode: string }>()
   const { data: programs, isSuccess } = useGetProgramFilter()
   const [isExporting, setIsExporting] = useState(false)
   const divRef = useRef<HTMLDivElement>(null)
   const { mutate: exportFile } = useMutation({
      mutationFn: getOperationExportList,
   })
   const defaultProgram =
      programs && programs.items ? programs.items.find((program) => program.isDefault)?.id : undefined
   const currentValues = getCurrentValues(location.search, defaultProgram)
   const handleCopy = () => {
      if (divRef.current) {
         const textToCopy = divRef.current.textContent || ''
         navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
               toast.info('Texto copiado al portapapeles: ')
            })
            .catch((err) => {
               toast.info('Error al copiar el texto: ')
            })
      }
   }
   const columns = useMemo(
      () => [
         {
            header: intl.formatMessage({ id: 'operations.table.operation.id' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <Link to={`/${accountCode}/partners/${row.original.member.id}/operation/${row.original.id}`}>
                     {row.original.id ? row.original.importId : '--'}
                  </Link>
               )
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.operation.partner' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <Link to={`/${accountCode}/partners/${row.original.member.id}/operations`}>
                     {row.original.member.fullName}
                     <div className="text-sm text-neutral-400">{row.original.member.email}</div>
                  </Link>
               )
            },
            enableSorting: false,
         },
         {
            header: () => (
               <div className="text-right"> {intl.formatMessage({ id: 'operations.table.partner.amount' })}</div>
            ),
            accessorKey: 'amount',
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div ref={divRef} className="text-right" onClick={handleCopy}>
                     {row.original.amount ? (
                        <Amount amount={row.original.amount} minimumFractionDigits={2} maximumFractionDigits={2} />
                     ) : (
                        '--'
                     )}
                  </div>
               )
            },
            enableSorting: false,
         },
         {
            header: () => (
               <div className="text-center">{intl.formatMessage({ id: 'operations.table.partner.id' })}</div>
            ),
            accessorKey: 'member',
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div ref={divRef} className="cursor-pointer text-center" onClick={handleCopy}>
                     {row.original.member.externalId ? row.original.member.externalId : '--'}
                  </div>
               )
            },
            enableSorting: false,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.generic.type' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div>
                     <div>{row.original.serviceType}</div>
                     {row.original.reservationCode ? (
                        <Link
                           to={`${process.env.REACT_APP_CRS_URL}/#/${accountCode}/itineraries/${row.original.reservationUuid}`}
                           className="text-blue-600 dark:text-blue-500 hover:underline text-sm"
                           target="_blank"
                        >
                           {row.original.reservationCode}
                        </Link>
                     ) : null}
                  </div>
               )
            },
            enableSorting: false,
         },
         {
            header: () => <div className="text-center">{intl.formatMessage({ id: 'operations.table.type' })}</div>,
            accessorKey: 'operationType',
            cell: ({ row }: { row: { original: Operation } }) => {
               return (
                  <div className="text-center">
                     {row.original.operationType
                        ? intl.formatMessage({ id: `operations.table.type.${row.original.operationType}` })
                        : '--'}
                     <div className="text-sm text-neutral-400">
                        {row.original.operationStatus
                           ? intl.formatMessage({ id: `operations.table.type.${row.original.operationStatus}` })
                           : '--'}
                     </div>
                  </div>
               )
            },
            enableSorting: false,
         },

         {
            header: () => <div className="text-right">{intl.formatMessage({ id: 'operations.table.points' })}</div>,
            accessorKey: 'accumulatedPoints',
            cell: ({ row }: { row: { original: Operation } }) => {
               const typeOfPoints = row.original?.operationType ? (
                  row.original.operationType === 'REDEMPTION' ? (
                     <div className="text-right text-red-600 font-semibold"> {`-${row.original.pointsRedeemed}`} </div>
                  ) : (
                     <div className="text-right text-green-600 font-semibold">{`+${row.original.pointsAccumulated}`}</div>
                  )
               ) : null
               return typeOfPoints ? typeOfPoints : '--'
            },
            enableSorting: true,
         },

         {
            header: () => <div className="text-center">{intl.formatMessage({ id: 'operations.table.CN' })}</div>,
            accessorKey: 'roomNights',
            cell: ({ row }: { row: { original: Operation } }) => {
               return <div className="text-center">{row.original.roomNights}</div>
            },
            enableSorting: true,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.date.stay' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               const isoDate = createDateFromISO(row.original.reservationDate).setLocale(locale)
               return <div>{isoDate.isValid ? isoDate.toFormat(intl.formatMessage({ id: 'date.format' })) : '--'}</div>
            },
            enableSorting: true,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.date.application' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               const isoDate = createDateFromISO(row.original.applicationDate).setLocale(locale)
               return (
                  <div>
                     {isoDate.toFormat(intl.formatMessage({ id: 'date.format' }))}
                     <div className="text-sm text-neutral-400">{`${isoDate.toFormat('h:mm a')}`}</div>
                  </div>
               )
            },
            enableSorting: true,
         },
         {
            header: intl.formatMessage({ id: 'operations.table.registrationChannel' }),
            cell: ({ row }: { row: { original: Operation } }) => {
               return <div>{row.original.channel}</div>
            },
            enableSorting: false,
         },
      ],
      [intl, locale, accountCode],
   )

   const onFilter = (data: OperationFilterForm) => {
      const request = Object.assign({}, data)
      // request.from = undefined
      // request.to = undefined
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      ) //TODO: revisar si se soluciona el bug que encodea la coma https://github.com/ljharb/qs/issues/410
   }

   const onPageChange = (page: { page: number; size: number }) => {
      const request = Object.assign({}, currentValues, { ...page })
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }
   const onSort = (sort: () => Array<{ id: string; desc: boolean }>) => {
      const sortField = sort()?.[0]
      if (!sortField) return
      const { sort: sortFilter, ...currentValues } = getCurrentValues(location.search)
      const sort2 = sortFilter.includes('asc')
      const request = Object.assign(
         {},
         currentValues,
         !sort2
            ? {
                 sort: `${sortField.id},${sortField.desc ? 'desc' : 'asc'}`,
              }
            : null,
      )
      navigate(
         `?${qs.stringify(request, {
            arrayFormat: 'comma',
            allowDots: true,
            encode: false,
         })}`,
      )
   }

   const onExport = () => {
      const toastId = toast.loading('Generando archivo espere un momento......', { autoClose: false })
      const { loyaltyPlanIds, query } = currentValues
      let request = {
         account: accountCode || '',
         emailRecipients: [],
         filters: {
            ...(query ? { query: query } : {}),
            ...(loyaltyPlanIds.length ? { loyaltyPlanId: Number(loyaltyPlanIds) } : { loyaltyPlanId: defaultProgram }),
         },
      }
      setIsExporting(true)
      exportFile(request, {
         onSuccess: (response) => {
            let excellName = `operations.xlsx`
            const blob = new Blob([response], {
               type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = excellName
            document.body.appendChild(link)
            toast.update(toastId, {
               render: `Exportación completada 🎉`,
               type: 'success',
               isLoading: false,
               autoClose: 3000,
               position: 'bottom-center',
            })
            link.click()
            document.body.removeChild(link)
            setIsExporting(false)
         },
         onError: (error) => {
            setIsExporting(false)
            toast.update(toastId, {
               render: 'Error en la exportación 😢',
               type: 'error',
               isLoading: false,
               autoClose: 3000,
            })
         },
      })
   }

   const onImportPms = () => {
      navigate('importPms')
   }
   return (
      <>
         <Title
            className="flex justify-between gap-x-2  items-center "
            value={intl.formatMessage({ id: 'titles.operations' })}
         ></Title>
         <div className="flex flex-col gap-y-4 mx-4">
            <Card className="py-4">
               {isSuccess && programs.items && programs.items.length > 0 && (
                  <>
                     <Filters
                        className="px-4"
                        onSubmit={onFilter}
                        defaultValues={currentValues}
                        defaultProgram={defaultProgram}
                     />
                     {/* <div>
                        <ResumeOperations
                           request={{
                              loyaltyPlanId: Number(currentValues.loyaltyPlanIds),
                              dates: {
                                 from: currentValues.from,
                                 to: currentValues.to,
                              },
                              operationStatus: currentValues.operationStatus,
                              channelIds: currentValues.channelIds,
                              query: currentValues.query,
                           }}
                        />
                     </div> */}

                     <TableOperations
                        currentValues={currentValues}
                        onPageChange={onPageChange}
                        columns={columns}
                        defaultProgram={defaultProgram}
                        onSortChange={onSort}
                     >
                        <div className="flex justify-between gap-x-2">
                           <Button onClick={onImportPms} className={'flex gap-x-2 items-center text-[12px]'}>
                              <FontAwesomeIcon icon={faDownload} className={'text-gray-500 text '} />
                              {intl.formatMessage({ id: 'operations.import.PMS' })}
                           </Button>
                           <Button
                              onClick={onExport}
                              disabled={isExporting}
                              className={'flex gap-x-2 items-center text-[12px]'}
                           >
                              {isExporting ? (
                                 <FontAwesomeIcon icon={faArrowsRotate} className={'text-gray-500 text '} />
                              ) : (
                                 <FontAwesomeIcon icon={faShareFromSquare} className={'text-gray-500 text '} />
                              )}
                              {intl.formatMessage({ id: 'operations.export' })}
                           </Button>
                        </div>
                     </TableOperations>
                  </>
               )}
            </Card>
         </div>
      </>
   )
}
