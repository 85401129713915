import { Level } from '../components/partners'
import { apiCall } from '../utils/api'
export type GetLevelListRequest = {
   page?: number
   pageSize?: number
   loyaltyPlanIds?: number | string
   levelIds?: string
}
export async function getLevelList(request?: GetLevelListRequest): Promise<{ items: Array<Level>; totalSize: number }> {
   const { levelIds, ...rest } = request || {}
   const result = await apiCall({
      url: `${process.env.REACT_APP_PUBLIC_STAGE_API}/loyalty/v1/levels`,
      method: 'GET',
      params: rest,
   })

   return { items: result.data, totalSize: parseInt(result.headers['x-total-count']) }
}
