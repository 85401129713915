import React from 'react'
import { Link } from 'react-router-dom'

type BreadcrumbProps = {
   items: Array<{
      url?: string
      label?: string
   }>
}

export const Breadcrumb = ({ items }: BreadcrumbProps) => {
   return (
      <nav className="flex p-4 pb-0" aria-label="Breadcrumb">
         <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            {items.map((item, index) => {
               return index === 0 ? (
                  <li className="inline-flex items-center" key={item.label}>
                     {item.url ? (
                        <Link
                           to={item.url}
                           className="inline-flex items-center text-sm font-medium text-amber-500 hover:text-amber-700"
                        >
                           {item.label}
                        </Link>
                     ) : (
                        <span className="text-sm font-medium text-gray-500">{item.label}</span>
                     )}
                  </li>
               ) : (
                  <li key={item.label}>
                     <div className="flex items-center">
                        <svg
                           className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                           aria-hidden="true"
                           xmlns="http://www.w3.org/2000/svg"
                           fill="none"
                           viewBox="0 0 6 10"
                        >
                           <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 9 4-4-4-4"
                           />
                        </svg>
                        {item.url ? (
                           <Link
                              to={item.url}
                              className="inline-flex items-center text-sm font-medium text-amber-500 hover:text-amber-700"
                           >
                              {item.label}
                           </Link>
                        ) : (
                           <span className="text-sm font-medium text-gray-500">{item.label}</span>
                        )}
                     </div>
                  </li>
               )
            })}
         </ol>
      </nav>
   )
}

export default Breadcrumb
