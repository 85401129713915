import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { getProgramList, GetProgramListRequest, plans } from '../api/program'

export type ProgramListState = {
   isPristine: boolean
   isFetching: boolean
   items: Array<plans>
   selected?: number | null
   totalSize: number
}

const initialState: ProgramListState = {
   isPristine: true,
   isFetching: false,
   items: [],
   totalSize: 0,
   selected: null,
}

export const getProgramListThunk = createAsyncThunk(
   'getProgramList',
   async (request: GetProgramListRequest | undefined, { rejectWithValue }) => {
      try {
         return await getProgramList(request)
      } catch {
         return rejectWithValue(null)
      }
   },
)
export const programListSlice = createSlice({
   name: 'programListSlice',
   initialState,
   reducers: {
      clear: () => {
         return initialState
      },
      select: (state, action: PayloadAction<number>) => {
         state.selected = action.payload
      },
   },
   extraReducers(builder) {
      builder.addCase(getProgramListThunk.pending, (state) => {
         state.isPristine = false
         state.isFetching = true
         state.selected = null
      })
      builder.addCase(getProgramListThunk.fulfilled, (state, action) => {
         const { payload } = action
         state.isPristine = false
         state.isFetching = false
         state.items = payload.items
         state.totalSize = payload.totalSize
         state.selected = payload.items[0].id
      })
      builder.addCase(getProgramListThunk.rejected, (state) => {
         state.isPristine = false
         state.isFetching = false
         state.selected = null
      })
   },
})

export const { clear, select } = programListSlice.actions

export default programListSlice.reducer
